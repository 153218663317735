import React from "react"
import styled from "styled-components"

const Instagram = props => (
  <InstagramWrapper href="https://www.instagram.com/tajwarshelim/" id={props.id} props={props}>
        <div className="insta-img-container">
            <img src={'../../insta (15).jpg'}></img>
        </div>

        <div className="insta-img-container">
            <img src={'../../insta (14).jpg'}></img>
        </div>

        <div className="insta-img-container">
            <img src={'../../insta (13).jpg'}></img>
        </div>

        <div className="insta-img-container">
            <img src={'../../insta (12).jpg'}></img>
        </div>

        <div className="insta-img-container">
            <img src={'../../insta (11).jpg'}></img>
        </div>

        <div className="insta-img-container">
            <img src={'../../insta (10).jpg'}></img>
        </div>

        <div className="insta-img-container">
            <img src={'../../insta (9).jpg'}></img>
        </div>

        <div className="insta-img-container">
            <img src={'../../insta (8).jpg'}></img>
        </div>

        <div className="insta-img-container">
            <img src={'../../insta (7).jpg'}></img>
        </div>
  </InstagramWrapper>
)

const InstagramWrapper = styled.a`
    display: flex;
    display:-webkit-flex
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 29%;
    justify-content: center;

    .insta-img-container {
        display: flex;
        display:-webkit-flex
        flex: 1 0 auto;
        margin: 3px;
        flex-basis: 31.5%
    }

    @media (max-width: 768px) {
        .insta-img-container {
            flex-basis: 45%
        }
    }
`

export default Instagram