import React, { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Title from "../components/title"
import Instagram from "../components/instagram"
import Storybook from "../components/storybook"
import MiniBlogPost from "../components/blogPostMini"

const Index = ({ data, location }) => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.utils.toArray('.taj-about').forEach((el, index) => { 
            let tl = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                start: "top 70%",
              }
            });
            
            tl
            .fromTo(el, { opacity: 0, y: "+=100"}, {opacity: 1, y: 0, duration: 1, immediateRender: false});
          })
    }, []);

    return (
      <Layout location={location}>
        <SEO
          title="About"
          keywords={[`blog`, `law`, `barristers`, `tajwar`, `shelim`, `tajwar shelim`]}
        />

        <div className="row taj-about-row" id="about">
            <div className="col-12 col-md-5 text-xl-right text-center">
                <img className="img-responsive" src={'../../taj-wig.png'} />
            </div>

            <div className="col-12 col-md-6 mt-5">
                <Title>About Me</Title>
                <ul className="text-paragraph">
                    <li>
                        I work as the <a className="body-link" href="https://www.migrantvoice.org/">Campaign Organiser for Migrant Voice <i class="fas fa-external-link-alt color-primary"></i></a>. Advocating and demanding change with migrants and refugees in the United Kingdom for equal rights and justice
                    </li>
                    <li>
                        I manage projects and provide consultancy services. Having supported the delivery of over <u>25 projects</u> with partners such as the Ministry of Justice, Tudor Trust and various Council’s
                    </li>
                    <li>
                        I provide legal advice. Having represented over <u>50 refugees</u> on the global stage, drafting legal submissions to various Bodies and advocating for their rights
                    </li>
                    <li>
                        I act as a Trustee for Refugee Cafe in Lewisham, alongside supporting Aspiring Barristers Ltd, The Lawyers Portal and The LADS Project
                    </li>
                    <li>
                        I am an award winning businessman and restaurateur, winning the Catering Circle's Restaurant Star Show Awards Season 7
                    </li>
                    <li>
                        I game. Ever wanted to hear a legal perspective of some of the world's best role playing games? I livestream on Twitch
                    </li>
                </ul>
            </div>

            <div className="row mt-5" id="about-main">
                <div className="col-12 col-lg-3 col-md-5 about-img">
                    <img className="img-responsive" src={'../../taj-migrant.jpg'} />
                </div>

                <div className="col-12 col-lg-3 col-md-6 about-text">
                    <Title>Migrant Voice</Title>
                    <p className="text-paragraph">
                        I am the Campaign Organiser for Migrant Voice, a migrant led, national organisation building a movement and growing a community demanding justice and equal rights. 
                        We put migrant voices at the centre of the migration debate through developing the skills and confidence of migrants, including asylum seekers and refugees across the UK. 
                        Find out more <a className="body-link" href="https://www.migrantvoice.org/">here <i class="fas fa-external-link-alt color-primary"></i></a>
                    </p>
                </div>

            <div className="col-12 col-lg-3 col-md-5 about-img">
                <img className="img-responsive" src={'../../refugee.jpeg'} />
            </div>

            <div className="col-12 col-lg-3 col-md-6 about-text">
                <Title>Refugee Cafe</Title>
                <p className="text-paragraph">
                I am a Trustee and Legal Advisor to Refugee Cafe, working to support refugees in Lewisham with training programmes and community building initiatives through the love of food. 
                We're at an exciting stage of our journey, and you can find out more about us <a className="body-link" href="https://refugeecafe.org.uk/">here <i class="fas fa-external-link-alt color-primary"></i></a>
                </p>
            </div>

            <div className="col-12 col-lg-3 col-md-5 about-img">
                <img className="img-responsive" src={'../../viceroy.jpeg'} />
            </div>

            <div className="col-12 col-lg-3 col-md-6 about-text">
                <Title>Viceroy of Windsor</Title>
                <p className="text-paragraph">
                    I have been in the restaurant industry since 14, the <a className="body-link" href="https://www.viceroyofwindsor.co.uk/">Viceroy <i class="fas fa-external-link-alt color-primary"></i></a> is my home. 
                    A lifetime member of the British Bangladeshi Catering Association and the winner of <a className="body-link" href="https://cateringcircle.co.uk/index.html">Catering Circle's <i class="fas fa-external-link-alt color-primary"></i></a> Star Show Awards against 700 restaurateurs across the UK, 
                    debating current issues, showcasing innovation and arguing the importance of sustainability and eco-friendly practices in an ever-changing world. 
                    Watch my live performances <a className="body-link" href="https://www.youtube.com/channel/UC8WTk94c3IplHfcOypzmGUg">here <i class="fas fa-external-link-alt color-primary"></i></a> 
                </p>
            </div>

            <div className="col-12 col-lg-3 col-md-5 about-img">
                <img className="img-responsive" src={'../../life-after-education.jpg'} />
            </div>

            <div className="col-12 col-lg-3 col-md-6 about-text">
                <Title>Consultancy and Project Management</Title>
                <p className="text-paragraph">
                    At <a className="body-link" href="https://collaboratecic.com/">Collaborate <i class="fas fa-external-link-alt color-primary"></i></a> we work towards building the parameters for effective systems change, supporting better models of working and how we think about delivering services. 
                    Shaped by the belief that collaboration is how we can achieve things together that we cannot do alone, and in time, reshape those ends to reflect our shared values. 
                    Paving the way to a fairer, kinder and more sustainable world

                    You can read about some of my projects <a className="body-link" href="/blog">here <i class="fas fa-external-link-alt color-primary"></i></a> and my report looking at Diversity in the Charity Sector <a className="body-link" href="https://www.tajwarshelim.co.uk/blog/human-learning-systems-equity/">here <i class="fas fa-external-link-alt color-primary"></i></a>
                </p>
            </div>

            <div className="col-12 col-lg-3 col-md-5 about-img">
                <img className="img-responsive" src={'../../taj-law-school.jpg'} />
            </div>

            <div className="col-12 col-lg-3 col-md-6 about-text">
                <Title>Asylum and Immigration</Title>
                <p className="text-paragraph">
                    I have been a Caseworker supporting refugees across the globe for over 5 years with asylum applications in the United Kingdom and Spain. 
                    I provide pro-bono advice, support and guidance to those that need it most. Find out about your rights <a className="body-link" href="https://www.tajwarshelim.co.uk/your-rights/">here <i class="fas fa-external-link-alt color-primary"></i></a> <br /><br />

                    My <a className="body-link" href="https://www.tajwarshelim.co.uk/blog/asylum-kingdom-reforming-the-uk-policy-on-asylum/">Practitioner Article <i class="fas fa-external-link-alt color-primary"></i></a> showcases practical interpretation of English Asylum Law, used by asylum seekers and caseworkers alike on current issues and procedures 
                </p>
            </div>

            <div className="col-12 col-lg-3 col-md-5 about-img">
                <img className="img-responsive" src={'../../taj-university.jpg'} />
            </div>

            <div className="col-12 col-lg-3 col-md-6 about-text">
                <Title>Casework and Drafting</Title>
                <p className="text-paragraph">
                    Before Law School I completed a Mini-Pupillage at Temple Court Chambers shadowing Barrister Afjal Zamee, assisting his casework. 
                    I worked with the Afghanistan and Central Asian Association, providing pro-bono legal advice and supporting marginalised women in the UK. 
                    I interned at Redress, working mainly international detention cases, notably that of Nazanin Zaghari-Radcliffe and Jagtar Singh Johal. 
                    My time at the Spanish League for Human Rights gave me my first opportunity to directly support refugees in Madrid, advocating directly on their behalf in the Spanish Courts.
                </p>
            </div>

            <div className="col-12 col-lg-3 col-md-5 about-img">
                <img className="img-responsive" src={'../../taj-childhood.jpg'} />
            </div>

            <div className="col-12 col-lg-3 col-md-6 about-text">
                <Title>Barrister Gaming</Title>
                <p className="text-paragraph">
                    I have always been a gamer at heart. Engrossed in the fantasy of worlds and empire building, from World of Warcraft and Runescape to Age of Empires and Halo. 
                    The adrenaline, the storylines, the chance to be the hero you always wanted to be. And I get to share that passion on my <a className="body-link" href="https://www.tajwarshelim.co.uk/twitch/">Twitch stream <i class="fas fa-external-link-alt color-primary"></i></a> 
                </p>
            </div>

            <div className="col-12 col-lg-3 col-md-5 about-img">
                <img className="img-responsive" src={'../../taj-early-years.jpg'} />
            </div>

            <div className="col-12 col-lg-3 col-md-6 about-text">
                <Title>About Taj</Title>
                <p className="text-paragraph">
                    My family are originally from Bangladesh and my parents moved into Windsor in the early 90s and we have been here ever since. 
                    I attended St Edwards Middle School and afterwards  the Windsor Boys School where I reflected on my experiences on my own podcast. 

                    It was at University where I knew what I wanted to do in this life, where I thought my purpose lied. 
                    That led me around the world working with refugees and the world's disadvantaged, on the path of becoming a Barrister. 
                    It was not smooth sailing, but muddied waters and cloudy skies, and that is where our <a className="body-link" href="https://www.tajwarshelim.co.uk/blog/the-journey-part-i-reclaim-your-heart/">Journey Begins... <i class="fas fa-external-link-alt color-primary"></i></a>
                </p>
            </div>
        </div>
        </div>
      </Layout>
    )
}


export default Index
