import React from "react"
import styled from "styled-components"

const Storybook = props => {

    return (
        <StorybookWrapper id={props.id} props={props}>
            <LetterWrapper>
                <Letter>
                    {props.letter}
                </Letter>
            </LetterWrapper>

            <Words>
                <Word>
                    {props.firstword}
                </Word>
                <Word>
                    {props.secondword}<span className="color-primary">.</span>
                </Word>
            </Words>
        </StorybookWrapper>
    )
}

const LetterWrapper = styled.div`
    display: flex;
    font-size: 90px;
    align-content: start;
    font-weight: bold;
    color: white;
`
const Letter = styled.div`
    display: inline-block;
    vertical-align: top;
`

const Word = styled.div`
    display: flex;
    font-size: 25px;
    line-height: 22px;
    font-weight: bold;
    color: white;
`

const Words = styled.div`
    display: flex;
    margin-top: 57px;
    flex-direction: column;
`

const StorybookWrapper = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 500px;
`

export default Storybook
