import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

const MiniBlogPost = props => {
    return (
        <MiniBlogPostWrapper className="blog-mini-wrapper" id={props.id} props={props}>
            <a className="mini-link" style={{ boxShadow: `none` }} href={`/blog${props.slug}`}>
                <div className="mini-post-details">
                    <h3 className="mini-post-title">
                        {props.title.trim()}<span class="color-primary">.</span>
                    </h3>
                    <p className="text-paragraph blog-mini-desc">
                        {props.description}
                    </p>
                </div>

                <div className="read-more-link">
                    <a className="body-link">
                        Read more &nbsp; <i class="fas fa-external-link-alt color-primary"></i>
                    </a>
                </div>

                <div className="mini-post-category">
                    {props.category}
                </div>
            </a>
        </MiniBlogPostWrapper>
    )
}

const MiniBlogPostWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 25px;
    max-height: 250px;
    min-height: 200px;
    overflow: hidden;
    position: relative;

    .mini-link:hover {
        text-decoration: none;

        h3 {
            transition: ease-in-out .3s;
        }

        .mini-post-category {
            opacity: 0.7;
            transition: ease-in-out .2s;
        }

        a {
            color: var(--primary-blue);
            transition: ease-in-out .2s;
        }
    }

    .read-more-link {
        position: absolute;
        bottom: 45px;
        left: 0;
    }

    .mini-post-category {
        transition: ease-in-out .2s;
        text-transform: uppercase;
        position: absolute;
        top: -34px;
        right: 0;
        font-size: 100px;
        z-index: -1;
        color: var(--primary-blue);
        font-weight: bold;
        opacity: 0.3;
    }

    .mini-post-details {
        text-align: left;

        h3 {
            color: white;
            position: relative;
            padding: 5px 0px;
            margin-left: 2px;
            font-size: 23px;
            font-weight: bold;
            display: block;
            max-width: 400px;
            text-decoration: none !important;
            transition: ease-in-out .3s;

            .read-text {
                font-size: 14px;
            }
        }

        h3:hover {
            text-decoration: none;
        }

        a:hover {
            text-decoration: none !important;
        }
    }

    .mini-post-details:hover {
        h3 { text-decoration: none };
        a { 
            transition: ease-in-out 0.2s;
            color: var(--primary-blue);
            text-decoration: none 
        };
        p { text-decoration: none };
    }

    .blog-mini-desc {
        background: transparent;
    }

    @media (max-width: 768px) {
        margin: 15px 0px;
        max-height: 1000px;

        .mini-post-category {
            display: none;
        }

        .read-more-link {
            bottom: 5px;
        }
    }
`

export default MiniBlogPost