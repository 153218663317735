import React from "react"
import styled from "styled-components"

const Button = props => (
  <ButtonWrapper href={props.href} id={props.id} props={props}>{props.children}</ButtonWrapper>
)

const ButtonWrapper = styled.a`
    display: inline-block;
    color: #ff4d4d;
    background: #5b9bd5;
    border-radius: 100px;
    box-shadow: none;
    text-decoration: none;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 25px;
    border: none;
    transition: 0.3s;

    &:hover {
        cursor: pointer;
        color: white;
        background: #2f75b6;
        text-decoration: none;
        transition: 0.3s;   
    }
`

export default Button
